import { lazy, Suspense, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Input } from "antd";

const Router = () => {
  const [check,setCheck] = useState(true)
  const [value,setValue] = useState("")

  const handleChange = (event:any) => {
    const { name, value } = event.target;
    setValue(value)
    if(value=="vincereevinceremo")
      setCheck(true)
  }

  return (
      check?<Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
      </Suspense>
      :<>
      <Input
        type="text"
        name="codice"
        placeholder="Codice Beta Tester"
        value={value}
        onChange={handleChange}
      />
    </>
  );
};

export default Router;
