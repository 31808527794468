import { StyledButton } from "./styles";
import { ButtonProps } from "../types";

export const Button = ({ color, children, onClick, disabled }: ButtonProps) => (
  disabled==true?
  (<StyledButton disabled color={'gray'} onClick={()=>{}}>
    {children}
  </StyledButton>)
  :(<StyledButton color={color} onClick={onClick}>
      {children}
  </StyledButton>)
);
